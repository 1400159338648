<template>
  <section class="sams-story-section" :class="imageOrder">
    <a :href="route('about')">
      <figure>
        <img alt="Sam and Mum" :src="image" />
        <figcaption>
          <span>Sam, CEO and Founder with his mum</span>
        </figcaption>
      </figure>
    </a>
    <div class="col-span-5 col-start-8 lg:col-start-8 order-1">
      <div>
        <h3
          class="text-3xl sm:text-4xl md:text-3xl lg:text-3xl xl:text-4xl font-bold text-left mb-7 sm:mb-8 md:mb-10 xl:mb-12"
          data-testid="sams-story-header"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </h3>
        <div
          class="text-base xs:text-lg xl:text-xl 2xl:text-2xl text-left whitespace-pre-wrap"
          data-testid="sams-story-body"
        >
          <slot name="body">
            {{ body }}
          </slot>
          <div
            v-if="primaryCta"
            class="flex flex-col sm:flex-row sm:!w-fit gap-y-3.5 gap-x-5 mt-6 sm:mt-7 md:mt-8 lg:mt-10"
          >
            <OLButton
              variant="special"
              :size="buttonSize"
              class="sm:!w-fit text-base"
              :link-to="primaryCta.linkTo"
            >
              {{ primaryCta.text }}
              <Arrow />
            </OLButton>

            <OLButton
              v-if="secondaryCta"
              class="sm:!w-fit text-base"
              :size="buttonSize"
              variant="secondary"
              :link-to="secondaryCta.linkTo"
            >
              {{ secondaryCta.text }}
              <Arrow />
            </OLButton>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import OLButton from '@components/Button/OLButton.vue';
import Arrow from '@components/Icon/Arrow.vue';
import { computed } from 'vue';
import { useWindowSize } from '@vueuse/core';

const props = defineProps({
  body: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  image: {
    type: URL,
    default: new URL('/public/theme/img/sam-mum.jpg', import.meta.url),
  },
  primaryCta: {
    type: Object,
    default: null,
  },
  secondaryCta: {
    type: Object,
    default: null,
  },
  imageBottomOnMobile: {
    type: Boolean,
    default: false,
  },
  figureCaption: {
    type: String,
    default: ' Sam, CEO and Founder with his mum',
  },
});
const imageOrder = computed(() => {
  return props.imageBottomOnMobile ? 'flex-col-reverse' : 'flex-col';
});

const { width } = useWindowSize();

const isXl = computed(() => width.value > 1280);

const buttonSize = computed(() => (isXl.value ? 'md' : 'sm'));
</script>

<style lang="scss" scoped>
section.sams-story-section {
  @apply bg-[unset]
    flex
    gap-12 lg:gap-20
    lg:flex-row
    items-center
    mx-auto
    px-5 xs:px-8 sm:px-7 lg:px-20
    py-20 md:py-28 lg:py-36
    max-w-content;

  > a,
  > div {
    @apply w-full;
  }

  > a > figure {
    @apply relative overflow-hidden rounded-3xl;

    > img {
      @apply aspect-square
        object-cover
        w-full
        h-auto;
    }

    > figcaption {
      @apply absolute
        bottom-6 md:bottom-10 xl:bottom-16
        right-6 md:right-10 xl:right-16
        text-white
        text-xs
        text-right;

      > span {
        @apply relative;
        z-index: 1;
      }

      &::before {
        content: '';
        width: calc(100% + 1rem);

        @apply absolute
          -left-2
          -top-2
          z-0
          p-4
          h-full
          bg-black;
        filter: blur(1.2rem);
      }
    }
  }
}
</style>
